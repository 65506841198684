import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p> Try these GPTs from Openai Appstore:</p>

        <p>
        <a
          className="App-link"
          href="https://chat.openai.com/g/g-zw6JgpYby-bible-wisdom"
          target="_blank"
          rel="noopener noreferrer"   
        >  Bible Wisdom
        </a>
        </p>
        
        <p>
        For feedback Contact:
        </p>


        <a
          className="App-link"
          href="mailto:antonycjohnson@gmail.com"
          target="_blank"
          rel="noopener noreferrer"
        >
         antonycjohnson@gmail.com
        </a>
      </header>
    </div>
  );
}

export default App;
